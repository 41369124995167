import { useState, useEffect } from 'react';

export const useImageMode = () => {
    const [showImages, setShowImages] = useState(() => {
        const saved = localStorage.getItem('showImages');
        return saved !== null ? JSON.parse(saved) : true;
    });

    useEffect(() => {
        localStorage.setItem('showImages', JSON.stringify(showImages));
    }, [showImages]);

    const toggleImageMode = () => setShowImages(prev => !prev);

    return [showImages, toggleImageMode];
}; 