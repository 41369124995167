// Header.js
import React, { useEffect, useState } from 'react';
import './Header.css'; // Assuming you have a CSS file for styling
import { FaSearch, FaMoon, FaSun, FaImage } from 'react-icons/fa'; // Import the search icon and theme toggle icons
import Search from './Search'; // Import the Search component
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons'; // Import the regular user icon
import { faUser as faUserFilled } from '@fortawesome/free-solid-svg-icons'; // Import the filled user icon
import UserPanel from './UserPanel'; // Import the new UserPanel component

const Header = ({ 
    toggleLogin, 
    isLoggedIn, 
    onLogout, 
    toggleSearch, 
    user, 
    onLogoClick, 
    theme, 
    toggleTheme,
    showImages,
    toggleImageMode 
}) => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const scrollDifference = currentScrollY - lastScrollY;
            
            if (currentScrollY < 10) {
                setIsHeaderVisible(true);
            } else if (scrollDifference > 5) { // Scrolling down
                setIsHeaderVisible(false); 
            } else if (scrollDifference < -5) { // Scrolling up
                setIsHeaderVisible(true);
            }
            
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <header className={`app-header ${!isDesktop && !isHeaderVisible ? 'mobile-hidden' : ''}`}>
            <Link to="/" className="logo-link" onClick={onLogoClick}>
                <img src={require('../assets/lllogo.svg').default} alt="Stage Dive Philly Logo" className="logo" />
            </Link>
            <div className="icon-container">
                <div className="icon" onClick={toggleSearch}>
                    <FaSearch />
                </div>
                <div className={`icon ${!showImages ? 'icon-disabled' : ''}`} onClick={toggleImageMode}>
                    <FaImage />
                </div>
                <div className={`icon ${theme === 'dark' ? '' : 'icon-disabled'}`} onClick={toggleTheme}>
                    <FaMoon />
                </div>
                {isLoggedIn && user ? (
                    <div className="icon" onClick={togglePanel}>
                        <FontAwesomeIcon icon={faUserFilled} />
                    </div>
                ) : (
                    <div className="icon" onClick={() => toggleLogin()}>
                        <FontAwesomeIcon icon={faUserRegular} />
                    </div>
                )}
            </div>
            {isPanelOpen && (
                <UserPanel 
                    user={user} 
                    onLogout={onLogout} 
                    isOpen={isPanelOpen} 
                    togglePanel={togglePanel} 
                />
            )}
        </header>
    );
};

export default Header;
