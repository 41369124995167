import React, { useState, useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useSupabase } from './SupabaseProvider.tsx';
import { FaTimes } from 'react-icons/fa';
import './Login.css'; // Keep this import for any custom styles

function Login({ onClose, onLogin, warningMessage }) {
  const supabase = useSupabase();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (supabase) {
      setLoading(false);
    } else {
      setError('Failed to initialize Supabase client');
    }
  }, [supabase]);

  const handleAuthChange = async (event, session) => {
    if (event === 'SIGNED_IN' && session) {
      onLogin(session.user);
      onClose();
    }
  };

  if (loading) {
    return null;
  }

  if (error) {
    return <div className="login-loading-text">Error: {error}</div>;
  }

  return (
    <div className="login-overlay">
      <div className="login-container always-light">
        <button className="login-close-button" onClick={onClose}>
          <FaTimes />
        </button>
        {warningMessage && <p className="login-warning">{warningMessage}</p>}
        <h2 className="login-title">User Login</h2>
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: 'var(--highlight-color)',
                  brandAccent: 'var(--secondary-color)',
                },
              },
            },
            style: {
              button: { width: '100%' },
              anchor: { color: 'var(--text-color1)' },
              container: { color: 'var(--text-color1)' },
              divider: { background: 'var(--border-color)' },
              input: { 
                backgroundColor: 'var(--cardBackground-color)',
                color: 'var(--text-color1)',
                borderColor: 'var(--border-color)'
              },
              label: { color: 'var(--text-color1)' },
              message: { color: 'var(--text-color3)' },
            },
          }}
          providers={[]} // Removed all social buttons
          onAuthStateChange={handleAuthChange}
        />
      </div>
    </div>
  );
}

export default Login;
