import React from 'react';
import './FloatingLoginButton.css'; // Import the CSS for styling
import { FaSignInAlt } from 'react-icons/fa'; // Optional: Import an icon

const FloatingLoginButton = ({ toggleLogin }) => {
  return (
    <button 
      className="floating-login-button"
      onClick={() => toggleLogin('Sign up or Log In to save shows and get alerts!')} // Call toggleLogin with a custom message
    >
      <FaSignInAlt className="login-icon" /> {/* Optional icon */}
      Sign Up / Log In
    </button>
  );
};

export default FloatingLoginButton; 